<template>
  <bannerModelEditor
    :formDataItem="formDataItem"
    v-model="banner"
  ></bannerModelEditor>
</template>

<script>
export default {
  props: {
    popupConfig: Object,
    data: {
      type: Object,
      default: () => null,
    },
  },
  data: () => ({
    banner: null,
  }),
  created() {
    this.banner = this.$eagleLodash.cloneDeep(this.data)
  },
  computed: {
    formDataItem() {
      return this.popupConfig.formDataItem
    },
  },
  watch: {
    banner: {
      deep: true,
      handler() {
        this.$emit('updateData', this.$eagleLodash.cloneDeep(this.banner))
      },
    },
  },
  components: {
    bannerModelEditor: () => import('./bannerModelEditor.vue'),
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
